import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { MicroCombo } from "@dadoagency/gatsby-plugin-trustpilot-widget"
import StaticReviewSection from "../../StaticReviewSection"

const SatisfactionClientSectionWrapper = styled.div`
  display: flex;
  width: 100%;
`

const SatisfactionClientSectionContainer = styled.div`
  display: flex;
  max-width: ${({ page }) => (page === "surmatelas" ? "1300px" : "1200px")};
  width: 100%;
  margin: 0px auto;
  padding: 10px 20px;
  color: ${({ page }) => (page != "home" ? "#1b2a56" : "#262626")};

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 10px 0px;

  iframe {
    margin: 0px;
    max-width: 430px;
  }
`

const SectionTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  font-family: "BebasNeueBold";
  line-height: 1;
  margin: 0px 0px 10px 0px;

  @media (max-width: 550px) {
    font-size: 36px;
  }
`

const SectionSubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: "Gotham Book";
  line-height: 1.2;
  margin: 0px;

  @media (max-width: 550px) {
    display: none;
  }
`

const SectionMobileSubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: "Gotham Book";
  line-height: 1.2;
  margin: 0px;
  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`

const SatisfactionClientSection = ({ intl, data, page, id }) => {
  return (
    data && (
      <SatisfactionClientSectionWrapper
        id={id}
        className={id ? "lyneup-page-section" : ""}
      >
        <SatisfactionClientSectionContainer page={page}>
          <ColumnBox>
            {data.title && (
              <SectionTitle
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            {data.subtitle && (
              <SectionSubTitle
                dangerouslySetInnerHTML={{
                  __html: data.subtitle,
                }}
              />
            )}
            {data.mobile_subtitle && (
              <SectionMobileSubTitle
                dangerouslySetInnerHTML={{
                  __html: data.mobile_subtitle,
                }}
              />
            )}
          </ColumnBox>
          <ColumnBox>
            <StaticReviewSection />
            {/* <MicroCombo
              language={intl.locale}
              culture="FR"
              theme="light"
              width="100%"
              height="20px"
            /> */}
          </ColumnBox>
        </SatisfactionClientSectionContainer>
      </SatisfactionClientSectionWrapper>
    )
  )
}

export default injectIntl(SatisfactionClientSection)
